/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
export const Debounce = (fn, t) => {
  const delay = t || 500
  let timer
  return function() {
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, args)
    }, delay)
  }
}
/**
 * 函数节流
 * @param fn
 * @param interval
 * @returns {Function}
 * @constructor
 */
export const Throttle = (fn, t) => {
  let last
  let timer
  const interval = t || 500
  return function() {
    const args = arguments
    const now = +new Date()
    if (last && now - last < interval) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        last = now
        fn.apply(this, args)
      }, interval)
    } else {
      last = now
      fn.apply(this, args)
    }
  }
}

export const formatTime = (date) => {
  const YMDHMS = date.split(' ')
  const YMD = YMDHMS[0].split('-')
  const HM = YMDHMS[1].split(':')
  return `${YMD[1]}月${YMD[2]}日 ${HM[0]}:${HM[1]}`
}

/*
* 使用方法
* Throttle不能使用箭头函数
getAliyunData:Throttle(function(){
  ...
}, 1000)
*/
