<template>
  <!-- 学徒考核转正 -->
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="name"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <div class="body">
        <a-steps
          type="navigation"
          :current="stepsCurrent"
          @change="onStepsChange"
        >
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>

        <div class="record-box" v-if="stepsCurrent < 2">
          <div class="timer">上次修改时间：2020年01月32日 12:23</div>
          <div class="btn-box">
            <a-button @click="onEdit" v-if="disabled">
              编辑内容
            </a-button>
            <a-button type="primary" @click="onSubmit" v-else>
              提交变更
            </a-button>
          </div>
        </div>
        <!-- 理论/实操考核-start -->
        <a-form-model
          ref="theoryRuleForm"
          :model="theoryForm"
          :rules="theoryRules"
          :label-col="{ span: 9 }"
          :wrapper-col="{ span: 5 }"
          v-show="stepsCurrent === 0"
        >
          <a-form-model-item label="理论教学讲师" prop="lectorId">
            <a-select
              allow-clear
              show-search
              placeholder="请输入要搜索的理论教学讲师、手机号"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleLectorSearch"
              v-model="theoryForm.lectorId"
              :disabled="disabled"
            >
              <a-select-option v-for="d in lectorSearchReturn" :key="d.id">
                {{ d.realName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="实操教学师傅" prop="parentId">
            <a-select
              allow-clear
              show-search
              placeholder="请输入要搜索的实操教学师傅、手机号"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :not-found-content="null"
              @search="handleMasterSearch"
              v-model="theoryForm.parentId"
              :disabled="disabled"
            >
              <a-select-option v-for="d in masterSearchReturn" :key="d.id">
                {{ d.realName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="理论考核时间" required prop="theorytime">
            <a-date-picker
              v-model="theoryForm.theorytime"
              type="date"
              placeholder="请选择理论考核时间"
              :disabled="disabled"
            />
          </a-form-model-item>
          <a-form-model-item label="实操考核时间" required prop="fieldtime">
            <a-date-picker
              v-model="theoryForm.fieldtime"
              type="date"
              placeholder="请选择实操考核时间"
              :disabled="disabled"
            />
          </a-form-model-item>
        </a-form-model>
        <!-- 理论/实操考核-end -->
        <!-- 上门实习记录-start -->
        <a-form-model
          ref="internshipRuleForm"
          :model="internshipForm"
          :rules="internshipRules"
          :label-col="{ span: 9 }"
          :wrapper-col="{ span: 5 }"
          v-show="stepsCurrent === 1"
        >
          <a-form-model-item label="上门实习时间" required prop="time">
            <a-date-picker
              v-model="internshipForm.time"
              type="date"
              placeholder="请选择上门实习时间"
              :disabled="disabled"
            />
          </a-form-model-item>
        </a-form-model>
        <!-- 上门实习记录-end -->
        <!-- 转正申请-start -->
        <a-form-model
          ref="applyRuleForm"
          :model="applyForm"
          :rules="applyRules"
          :label-col="{ span: 9 }"
          :wrapper-col="{ span: 5 }"
          v-show="stepsCurrent === 2"
        >
          <a-form-model-item label="师傅工衣人像（用作头像）" prop="avatar">
            <a-upload
              name="file"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadUrl"
              :data="{
                token: qiniuData.token
              }"
              :before-upload="beforeAvatarUpload"
              @change="handleAvatarChange"
            >
              <img
                v-if="applyForm.avatar"
                :src="applyForm.avatar"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item ref="jobNumber" label="师傅工号" prop="jobNumber">
            <a-input
              v-model="applyForm.jobNumber"
              placeholder="请输入师傅工号"
              @blur="
                () => {
                  $refs.jobNumber.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item label="所属区域" prop="areaId">
            <a-cascader
              :options="cascaderAreaOptions"
              placeholder="请选择区域"
              expand-trigger="hover"
              :field-names="fieldNames"
              v-model="applyForm.areaId"
            />
          </a-form-model-item>
          <a-form-model-item>
            <a-button class="submit-btn" type="primary" @click="onSubmit">
              确认转正
            </a-button>
          </a-form-model-item>
        </a-form-model>
        <!-- 转正申请-end -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import { Debounce } from '../../utils/public'
export default {
  data() {
    return {
      title: '',
      name: '',
      stepsCurrent: 0,
      steps: [
        {
          title: '理论/实操考核'
        },
        {
          title: '上门实习记录'
        },
        {
          title: '转正申请'
        }
      ],
      disabled: true,
      theoryForm: {
        // 理论/实操
        id: '',
        type: 'theoryfield',
        lectorId: undefined,
        parentId: undefined,
        theorytime: undefined,
        fieldtime: undefined
      },
      theoryRules: {
        // 理论/实操
        lectorId: [
          { required: true, message: '请选择理论教学讲师', trigger: 'change' }
        ],
        parentId: [
          { required: true, message: '请选择实操教学师傅', trigger: 'change' }
        ],
        theorytime: [
          { required: true, message: '请选择理论考核时间', trigger: 'change' }
        ],
        fieldtime: [
          { required: true, message: '请选择实操考核时间', trigger: 'change' }
        ]
      },
      internshipForm: {
        // 上门
        id: '',
        type: 'internship',
        time: undefined
      },
      internshipRules: {
        // 上门
        time: [
          { required: true, message: '请选择上门实习时间', trigger: 'change' }
        ]
      },
      applyForm: {
        id: '',
        type: 'apply',
        avatar: '',
        areaId: undefined,
        jobNumber: ''
      },
      applyRules: {
        avatar: [
          { required: true, message: '请上传师傅工衣人像', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择所属的地区', trigger: 'change' }
        ],
        jobNumber: [
          { required: true, message: '请输入师傅工号', trigger: 'blur' }
        ]
      },
      avatarLoading: false,
      uploadUrl: process.env.VUE_APP_QINIUPHOST,
      cascaderAreaOptions: [],
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      lectorSearchReturn: [],
      masterSearchReturn: []
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    this.theoryForm.id = query.id
    this.internshipForm.id = query.id
    this.applyForm.id = query.id
    this.name = query.name
  },
  methods: {
    onStepsChange(e) {
      if (this.stepsCurrent === e) return
      if (this.stepsCurrent === 0 && this.$refs.theoryRuleForm) {
        this.$refs.theoryRuleForm.resetFields()
      }
      if (this.stepsCurrent === 1 && this.$refs.internshipRuleForm) {
        this.$refs.internshipRuleForm.resetFields()
      }
      if (this.stepsCurrent === 2 && this.$refs.applyRuleForm) {
        this.$refs.applyRuleForm.resetFields()
      }
      if (e === 2 && !this.cascaderAreaOptions.length) {
        this.getArea()
      }
      if (!this.disabled) {
        this.disabled = true
      }
      this.stepsCurrent = e
    },
    async getArea() {
      const area = await this.$axios.getArea()
      this.cascaderAreaOptions = area.nested
    },
    handleAvatarChange(info) {
      // 头像上传
      if (info.file.status === 'uploading') {
        if (this.applyForm.avatar) {
          this.applyForm.avatar = ''
        }
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.applyForm.avatar = this.qiniuData.host + info.file.response.key
        this.avatarLoading = false
      }
    },
    async beforeAvatarUpload(file) {
      // 头像上传检验
      await this.$examineImgWH(file)
    },
    onEdit() {
      // 编辑理论内容
      this.disabled = false
    },
    onSubmit() {
      // 提交
      if (this.stepsCurrent === 0) {
        this.theorySubmit()
      }
      if (this.stepsCurrent === 1) {
        this.internshipSubmit()
      }
      if (this.stepsCurrent === 2) {
        this.applySubmit()
      }
    },
    theorySubmit() {
      // 理论/实操变更
      this.$refs.theoryRuleForm.validate((valid) => {
        if (valid) {
          this.theoryForm.theorytime = moment(
            this.theoryForm.theorytime
          ).format('YYYY-MM-DD')
          this.theoryForm.fieldtime = moment(this.theoryForm.fieldtime).format(
            'YYYY-MM-DD'
          )
          this.$axios.setApprenticeAssessment(this.theoryForm).then(() => {
            this.$message.success('操作成功')
          })
        }
      })
    },
    internshipSubmit() {
      // 上门实习
      this.$refs.internshipRuleForm.validate((valid) => {
        if (valid) {
          this.internshipForm.time = moment(this.theoryForm.time).format(
            'YYYY-MM-DD'
          )
          this.$axios.setApprenticeAssessment(this.internshipForm).then(() => {
            this.$message.success('操作成功')
          })
        }
      })
    },
    handleLectorSearch: Debounce(function(e) {
      // 讲师搜索
      if (!e) return
      const data = {
        keyrowd: e
      }
      this.$axios.getLector(data).then((res) => {
        this.lectorSearchReturn = res.data.data.list
      })
    }, 1000),
    handleMasterSearch: Debounce(function(e) {
      // 师傅搜索
      if (!e) return
      const data = {
        keyrowd: e
      }
      this.$axios.getMaster(data).then((res) => {
        this.masterSearchReturn = res.data.data.list
      })
    }, 1000),
    applySubmit() {
      // 转正
      this.$refs.applyRuleForm.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.applyForm))
          const areaId = data.areaId
          data.areaId = areaId[areaId.length - 1]
          this.$axios.setApprenticeAssessment(data).then(() => {
            this.$message.success('操作成功')
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.body {
  background-color: #fff;
  padding: 48px 32px;
}

.record-box {
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border: 1px solid #f0f2f5;
  margin-top: 16px;
}

.record-box .timer {
  color: rgba(37, 37, 37, 0.65);
}

.record-box .ant-btn:nth-child(2) {
  margin-left: 16px;
}

.ant-form {
  margin-top: 40px;
}

.ant-form .ant-calendar-picker {
  width: 100%;
}

.submit-btn {
  margin-left: 43em;
}
</style>
